@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,1000&display=swap");
html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif !important;
  padding-right: 0 !important;
}
