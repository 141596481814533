


.Heading1 {
  margin-top: 185px;
  font-size: 40px;
  font-weight: 100;
  line-height: 59px;
  letter-spacing: 0.083em;
}

.login-btn {
  position: absolute !important;
  right: 20px;
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  letter-spacing: 0.083em !important;
  text-transform: capitalize !important;
}

.countryName {
  display: flex;
  flex-direction: row;
  margin: 0px 10px;
  gap: 45px;
  margin-left: 40px;
}

.country {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.093em;
}

.parentContainer {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
  height: calc(100vh - 40px);
  transition: filter 0.5s ease;
  
}

.container.blurredContent{
  filter: blur(10px);
}

.blurredContent{
  filter: blur(10px);
}

.logo {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
}

.home-conent {
  margin: 200px 100px 0px;
}

.home-conent h6,
.home-conent p {
  font-size: 22px;
  font-weight: 100;
  line-height: 28px;
  letter-spacing: 0.058em;
}

#callNumberId {
  background-image: url("./media/BG\ image.jpg");
  height: 100vh;
}

#callNumberId .MuiCardContent-root{
  background-color: #00000029;
  position: relative;
}

#callNumberId .MuiCardContent-root::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  left: 0;
  top:0;
  backdrop-filter: blur(2px);
}

#callNumberId .MuiCardContent-root .MuiStack-root{
  position: relative;
  z-index: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
  margin-top: 32px;
}

.logoContainer {
  height: 60px;
  width: 156px;
  object-fit: contain;
}

.offerSection {
  margin-bottom: 90px;
  margin-top: 65px;
}


.titleDest {
  display: flex;
  max-width: 600px;
  margin-top: 20px;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.093em;
}

.container2 {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.container2 .service:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.service {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  max-width: 250px;
  padding: 20px;
}

.title {
  font-weight: 600;
}

.countryList {
  background-color: white;
  border: unset;
  border-radius: 100px;
  outline: none;
  color: black;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  background-image: url("./media/BG\ image.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.headerDiv {
  background: rgba(0, 0, 0, 0.5);
}

.headerScroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.offerName {
  margin-top: 50px;
  text-align: center;
  color: rgb(207, 13, 78);
  font-size: 22px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.058em;
}

.offers {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.arrow-btn {
  color: white;
  font-size: 80px !important;
  position: absolute;
  bottom: -80px;
}

.card-subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 30%;
  position: absolute;
  top: 57%;
  width: 35%;
  left: 32%;
}
.login-card{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 50%;
  width: 30%;
  position: absolute;
  top: 15%;
  right: 2%;
}

#contactUS{
  justify-content: space-between;
  padding:50px 187px;
}

.map{
  margin-left: 0px !important;
}

.to-number, .bg-set{
  background-color: transparent !important;
}
.to-number .Mui-error, .bg-set .Mui-error{
  color: #fff !important;
  margin-left: 0 !important;
}
.to-number #outlined-basic, .bg-set .MuiInputBase-root{
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.from-number{
  background-color: transparent !important;
}

.to-number #outlined-basic{
  background-color: white !important;
}

.logout-icon{
  width: 35px;
  height: 35px;
  vertical-align: middle;
}

.accManage{
  padding: 4px;
  /* border: 1px solid white; */
  background-color: transparent;
  color:white;
 
}

/* css for flags */

.Turkey {
  width: 16px; height: 16px;
    background: url('./media/flags.png') -46px -46px;
}

.Afghanistan {
  width: 16px; height: 16px;
    background: url('./media/flags.png') -10px -10px;
}

.Iran {
  width: 16px; height: 16px;
    background: url('./media/flags.png') -46px -10px;
}

.Nigeria {
  width: 16px; height: 16px;
    background: url('./media/flags.png') -10px -46px;
}

.Yemen {
  width: 16px; height: 16px;
    background: url('./media/flags.png') -82px -10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* offers flags */
.bgir {
  width: 20px; height: 20px;
  background: url('./media/offerFlags.png') -10px -10px;
}

.bgng {
  width: 20px; height: 20px;
  background: url('./media/offerFlags.png') -50px -10px;
}

.bgaf {
  width: 20px; height: 20px;
  background: url('./media/offerFlags.png') -10px -50px;
}

.bgye {
  width: 20px; height: 20px;
  background: url('./media/offerFlags.png') -50px -50px;
}

.bgtr {
  width: 20px; height: 20px;
  background: url('./media/offerFlags.png') -90px -10px;
}

/* MEDIA QUERY :: STARTS */

@media screen and (max-width: 1400px) {

 
  .login-btn {
    font-size: 16px !important;
  }

  .Heading1 {
    margin-top: 130px;
    font-size: 30px;
  }

  .titleDest {
    margin-top: 0px;
  }

  .description,
  .country {
    font-size: 13px;
  }

  .home-conent h6,
  .home-conent p,
  .offerName {
    font-size: 13px;
    margin-top: 0px !important;
  }

  .home-conent {
    margin: 100px 75px 0px;
  }

  .arrow-btn {
    bottom: -45px;
    font-size: 50px !important;
  }
}

@media screen and (max-width: 900px) {
  .homeContent {
    display: block;
  }
  .wrapperHomeContent {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .homeContent {
    display: none;
  }
  .wrapperHomeContent {
    display: block;
  }
  
}

@media screen and (max-width: 600px) {
  .mapImage {
    height: 300px;
  }
  .card-subscription{
    width: 96%;
    left: 2%;
    right: 2%;
  }
  .login-card{
    width:100%;
    right:0
  }
  #contactUS {
    padding: 50px 15px;
  } 

  #contactUS .map iframe{
    width: 100% !important;
  } 

  .rightNameContainer{
    visibility: hidden;
  }

  .leftNameContainer{
    visibility: visible;
    cursor:pointer;
  }
  .titleDest {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 600px) {
  .rightNameContainer{
    visibility: visible;
  }

  .leftNameContainer{
    visibility: hidden;
  }

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  .card-subscription{
    top: 37%;
    width: 60%;
    left: 19%;

  }
  .login-card{
    height: 39%;
    width: 43%;
    position: absolute;
    top: 9%;
    right: 4%;
  }
  #contactUS .map iframe{
    width: 100% !important;
  } 
}


.headerRightContainer{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}



/* MEDIA QUERY :: ENDS */
